<template>
  <div>
    <!-- Map -->
    <Map></Map>

    <!-- Content -->
    <div class="container mt-4">
      <!-- Quick nav -->
      <div class="row">
        <div class="col">
          <ul class="nav justify-content-center nav-pills nav-justified">
            <li class="nav-item me-2 mb-2" style="height: 100%;" v-for="station in $store.state.stations" :key="station.id">
              <router-link :to="'/station/' + station.id" class="nav-link active">{{ station.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- Alarms -->
      <div class="row mt-4">
        <div class="col">
          <!-- Alarms, devices="" for all alarms -->
          <Alarms :extra="{devices: ''}"></Alarms>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '../components/UI/OverviewMap'
import Alarms from '../components/UI/Alarms'

export default {
  metaInfo () {
    return {
      title: 'Ülevaade'
    }
  },
  components: { Map, Alarms },
  sockets: {
    //
  },
  mounted () {
    this.$socket.emit('requestRooms', [])
    this.$socket.emit('alarmLog', {})
  }
}
</script>
