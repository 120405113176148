import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiUrl: 'https://rts.dev.pilvepealne.ee:38000',
    eksekoApi: 'https://narvakom.dev.pilvepealne.ee:38000',
    config: {
      rights: {
        read: 1,
        write: 2
      }
    },
    user: { name: null, level: 1, email: null },
    websocket: false,
    stations: [],
    devices: [],
    alarms: [],
    groups: []
  },
  mutations: {
    // Save groups data to store
    setGroups (state, payload) {
      state.groups = payload
    },
    // Update current socket state for UI
    socketState (state, payload) {
      state.websocket = payload
    },

    // Set stations that we received from server
    setStations (state, payload) {
      state.stations = payload
    },

    // Set user data
    setUser (state, payload) {
      state.user = payload
      localStorage.removeItem('user')
    },

    // Update device data
    updateData (state, payload) {
      // Reformat data name
      const data = payload
      data.name = data.name.replace('.', '-')
      data.name = data.name.replace(/ /g, '-')

      // Find device from local memory
      let deviceIndex = state.devices.findIndex(obj => obj.serial === payload.serial)

      // Device not yet in local memory, add it
      if (deviceIndex === -1) {
        state.devices.push({ serial: data.serial })
        deviceIndex = state.devices.findIndex(obj => obj.serial === data.serial)
      }

      state.devices[deviceIndex].timestamp = data.timestamp
      Vue.set(state.devices[deviceIndex], data.name, data.value)
    },

    // Update alarms list
    updateAlarms (state, payload) {
      // state.alarms = payload
      payload.forEach(function (alarm) {
        if (state.alarms.findIndex(obj => obj.id === alarm.id) === -1) {
          state.alarms.push(alarm)
        }
      })
    }
  },
  getters: {
    // Retrieve data by device serial
    device: (state) => (id) => {
      const device = state.devices.find(device => device.serial === id)
      if (device === undefined) {
        return {}
      } else {
        return state.devices.find(device => device.serial === id)
      }
    },

    // Retrieve active alarms from store, either filtered by device or all
    activeAlarms: (state) => (id) => {
      if (id !== '') {
        return state.alarms.filter(alarm => id.includes(alarm.device_serial) && alarm.cleared_at === null)
      } else {
        return state.alarms.filter(alarm => alarm.cleared_at === null)
      }
    },

    // Retrieve past alarms from store, either filtered by device or all
    pastAlarms: (state) => (id) => {
      if (id !== '') {
        return state.alarms.filter(alarm => id.includes(alarm.device_serial) && alarm.cleared_at !== null)
      } else {
        return state.alarms.filter(alarm => alarm.cleared_at !== null)
      }
    },

    // User rights check
    permissions: (state) => (level) => {
      return (state.user.level >= state.config.rights[level])
    }
  },
  actions: {
    // Get groups data from DB
    getGroups () {
      const _this = this
      axios.get(_this.state.eksekoApi + '/getGroupList')
        .then((response) => {
          console.log(response.data)
          _this.commit('setGroups', response.data)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  modules: {
  }
})
