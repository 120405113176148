<template>
  <div id="map" style="background: #ececec; width: 100vw; height: 350px;"></div>
</template>

<script>
export default {
  data () {
    return {
      map: {
        object: null,
        markers: [],
        options: {
          zoom: 12, // Not required, map bounds is set automatically by markers
          gestureHandling: 'cooperative',
          clickableIcons: false,
          center: new window.google.maps.LatLng(58.809586, 25.440259),
          mapTypeId: window.google.maps.MapTypeId.ROADMAP
        }
      }
    }
  },
  watch: {
    '$store.state.stations': function () {
      this.initializeMap()
    }
  },
  mounted () {
    this.initializeMap()
  },
  methods: {
    // Initialize Google Maps map object
    initializeMap () {
      this.map.object = new window.google.maps.Map(document.getElementById('map'), this.map.options)
      this.drawMarkers()
    },

    // Draw station markers on map
    drawMarkers () {
      const _this = this
      this.$store.state.stations.forEach(function (station, index) {
        if (station.location.lat !== null && station.location.lng !== null) {
          _this.map.markers[index] = new window.google.maps.Marker({
            position: { lat: station.location.lat, lng: station.location.lng },
            title: station.name,
            map: _this.map.object,
            icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|0d6efd',
            label: { text: station.name, className: 'map-marker' }
          })
          _this.map.markers[index].addListener('click', function () {
            _this.$router.push('/' + station.type + '/' + station.id)
          })
        }
      })
      this.fitMapBounds()
    },

    // Fit map bounds and zoom around existing markers
    fitMapBounds () {
      const bounds = new window.google.maps.LatLngBounds()
      for (var i = 0; i < this.map.markers.length; i++) {
        bounds.extend(this.map.markers[i].getPosition())
      }

      this.map.object.fitBounds(bounds)
    }
  }
}
</script>

<style>
.map-marker {
  bottom: -30px !important;
  left: 0 !important;
  color: #000000 !important;
  position: relative;
  background-color: rgba(255,255,255,0.75);
  font-size: 18px !important;
  padding: 2px 4px;
}
</style>
