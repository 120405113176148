<template>
  <div class="accordion" id="eventsAccordion">
    <!-- Active events -->
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#activeEvents" aria-expanded="true" aria-controls="activeEvents">
          <h5>Aktiivsed sündmused ({{ $store.getters.activeAlarms(extra.devices).length }})</h5>
        </button>
      </h2>
      <div id="activeEvents" class="accordion-collapse collapse show" aria-labelledby="headingOne">
        <div class="accordion-body">
          <h6 v-if="$store.getters.activeAlarms(extra.devices).length === 0">Aktiivsed sündmused puuduvad</h6>
          <table v-else class="table">
            <thead>
            <tr>
              <th scope="col">Pumpla</th>
              <th scope="col">Sündmus</th>
              <th scope="col">Algus</th>
            </tr>
            </thead>
            <tbody>
            <tr class="table-danger" v-for="alarm in $store.getters.activeAlarms(extra.devices)" :key="alarm.id + alarm.raised_at + alarm.cleared_at">
              <td>{{ alarm.name }}</td>
              <td>{{ alarm.description }}</td>
              <td>{{ datetime(alarm.raised_at) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Past events -->
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pastEvents" aria-expanded="false" aria-controls="pastEvents">
          <h5>Möödunud sündmused ({{ $store.getters.pastAlarms(extra.devices).length }})</h5>
        </button>
      </h2>
      <div id="pastEvents" class="accordion-collapse collapse" aria-labelledby="headingTwo">
        <div class="accordion-body">
          <h6 v-if="$store.getters.pastAlarms(extra.devices).length === 0">Möödunud sündmused puuduvad</h6>
          <table v-else class="table">
            <thead>
            <tr>
              <th scope="col">Pumpla</th>
              <th scope="col">Sündmus</th>
              <th scope="col">Algus</th>
              <th scope="col">Lõpp</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="alarm in $store.getters.pastAlarms(extra.devices)" :key="alarm.id + alarm.raised_at + alarm.cleared_at">
              <td>{{ alarm.name }}</td>
              <td>{{ alarm.description }}</td>
              <td>{{ datetime(alarm.raised_at) }}</td>
              <td>{{ datetime(alarm.cleared_at) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['title', 'extra'],
  data () {
    return {}
  },
  methods: {
    datetime (timestamp) {
      return moment(parseInt(timestamp)).format('DD.MM.YYYY HH:mm')
    }
  }
}
</script>
