<template>
  <div>
    <div id="app" v-if="$store.state.stations.length !== 0">
      <!-- Header -->
      <Navbar/>
      <!-- Page content -->
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <!-- Footer -->
      <div class="container">
        <div class="row">
          <div class="col">
            <Footer/>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      Laadin andmeid...
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

export default {
  metaInfo: {
    title: 'Narvakom',
    titleTemplate: '%s | Narvakom'
  },
  components: { Navbar, Footer },
  data () {
    return {
      hostname: null,
      project: null
    }
  },
  sockets: {
    connect: function () {
      console.log('Server connection established')
      this.$socket.emit('setClient', { client: this.project, hostname: this.hostname, token: localStorage.getItem('localToken') })
      this.$store.commit('socketState', true)
    },
    disconnect: function () {
      console.log('Server connection lost')
      this.$store.commit('socketState', false)
    },
    stationsData: function (data) {
      this.$store.commit('setStations', data)
      console.log('Server sent stationsData:', data)
    },
    alarmLog: function (data) {
      console.log(data)
      this.$store.commit('updateAlarms', data)
    }
  },
  created () {
    this.hostname = (location.hostname === 'localhost') ? 'vinyl.dev.pilvepealne.ee' : location.hostname
    this.project = this.hostname.split('.')[0]
    // debugger
    // First thing, force SSO check
    window.protect()
    // Get stations
    this.$socket.emit('stationsData', this.project)

    // Request groups data from api
    this.$store.dispatch('getGroups')

    // Set user data
    const _this = this
    setTimeout(function () {
      _this.$store.commit('setUser', JSON.parse(localStorage.getItem('user')))
    }, 1000)
  }
}
</script>
