import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/station/:station_id',
    name: 'Station',
    component: () => import('../views/Station.vue')
  },
  {
    path: '/edit/alarms',
    name: 'EditAlarms',
    component: () => import('../views/EditAlarms.vue')
  },
  {
    path: '/edit/contacts',
    name: 'EditContacts',
    component: () => import('../views/EditContacts.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue')
  }
  /*
  {
    path: '/edit/notifications',
    name: 'EditNotifications',
    component: () => import('../views/EditNotifications.vue')
  }
   */
]

const router = new VueRouter({
  routes
})

export default router
