import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-socket.io'
import VueMeta from 'vue-meta'
import Vue2Filters from 'vue2-filters'
import '@/assets/style.css'
import moment from 'moment'
import 'moment/locale/et'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.config.productionTip = false

// const hostname = (location.hostname === 'localhost') ? 'wss://narvakom.pilv.app:39946' : 'wss://' + location.hostname + ':39946'
// const hostname = (location.hostname === 'localhost') ? 'wss://narvakom.dev.pilvepealne.ee:39946' : 'wss://' + location.hostname + ':39946'
// const hostname = 'wss://narvakom.dev.pilvepealne.ee:39946'
const hostname = 'wss://rts.pilv.app:39946'

Vue.use(new VueSocketIO({ debug: false, connection: hostname }))

Vue.use(VueMeta)
Vue.use(Vue2Filters)
Vue.use(VueSweetalert2)

Vue.prototype.moment = moment

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
